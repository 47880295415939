:root {
    --primary-color: #46FF83;
    --secondary-color: #444444;
    --background-color: #2d2d2d;
    --font-color: #ffffffde;
    --secondary-font-color: #b4b4b4;
}

#root {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

a {
	color: var(--primary-color);
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.5rem;
  	font-weight: 400;
  	letter-spacing: 0.18px;
}

p {
	color: #ffffff99;
}