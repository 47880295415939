.App {
    display: flex;
    flex-direction: column;
    width: 85vw;
    margin: 9% 0;
    border-radius: 4px;
    align-items: center;
    padding: 0 1rem 1rem;
    flex-grow: 1;
}

@media (min-width: 1100px) {
    .App {
        box-shadow: 0px 1px 5px 0px #00000033;
        background-color: var(--secondary-color);
        width: 80vw;
        padding: 4.25rem 1rem;
        margin: 4.25rem 0;
    }

    #flex-container {
        width: 70%;
    }
} 