html {
  background-color: var(--background-color);
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--font-color);
}
